import request from '@/utils/request';
export var defaultCountryData = {
  id: 0,
  enabled: true,
  code: '',
  name: '',
  zipCodeMinLength: 0,
  zipCodeMaxLength: 0
};
export var getCountries = function getCountries(params) {
  return request({
    url: '/countries',
    method: 'get',
    params: params
  });
};
export var getCountryById = function getCountryById(id) {
  return request({
    url: "/countries/".concat(id),
    method: 'get'
  });
};
export var updateCountry = function updateCountry(id, data) {
  return request({
    url: "/countries/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteCountry = function deleteCountry(id) {
  return request({
    url: "/countries/".concat(id),
    method: 'delete'
  });
};
export var createCountry = function createCountry(data) {
  return request({
    url: '/countries/',
    method: 'post',
    data: data
  });
};